const APP_INTENT = 'app://io.diwala.mobile';

const pathname = window.location.pathname;

const getFullPath = window.location.href;

const isAndroid = /android/i.test(window.navigator.userAgent);

const isLegacy = /login-mobile/i.test(pathname);

const appendFallbackLink = (link) => {
  const mainElem = document.querySelector('main');
  const linkElem = `<p>Not working? <a href="${link}">Open Diwala app</a></p>`;
  mainElem.innerHTML += linkElem;
};

const Init = () => {
  if (!isLegacy && isAndroid) {
    appendFallbackLink(APP_INTENT + pathname);
  } else {
    appendFallbackLink(getFullPath);
  }
};

window.onload = Init;
